export function setPageVisited(key) {
  if (typeof window !== "undefined" && typeof sessionStorage !== "undefined") {
    window.sessionStorage.setItem(key, true)
  }
}

export function isVisited(key) {
  if (typeof window !== "undefined" && typeof sessionStorage !== "undefined") {
    if (window.sessionStorage.getItem(key)) {
      return true
    } else {
      return false
    }
  }
}

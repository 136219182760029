import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeSectionTemplate from "../components/homeSectionTemplate"
import ReactPlayer from "react-player"
import "./index.css"
import SiteIntro from "../components/site-intro"
import { isVisited, setPageVisited } from "../utils/utils"
import { graphql } from "gatsby"

const videoOptions = {
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
    controls: 1,
    rel: 0,
    showinfo: 1,
    modestbranding: 0,
    playsinline: 1,
  },
}

const SectionsGrid = styled.div`
  overflow-y: scroll;
  height: 100vh;
  scroll-snap-type: y mandatory;
  -webkit-scroll-snap-type: y mandatory;
  -moz-scroll-snap-type: y mandatory;
`

class IndexPage extends React.PureComponent {
  state = {
    server: true,
    pageVisited: isVisited("Home"),
  }

  handlePageEnter = () => {
    setPageVisited("Home")
    this.setState({ pageVisited: true })
  }

  render() {
    const sectionsData = this.props.data.allWordpressPost.edges
    const { pageVisited } = this.state

    const images = sectionsData.map(item => item.node.acf.cover.source_url)

    if (!pageVisited) {
      return <SiteIntro images={images} removeIntro={this.handlePageEnter} />
    }

    return (
      <Layout>
        <SEO
          title="Home"
          keywords={[
            `film`,
            `trinidad`,
            `tobago`,
            `local`,
            `photography`,
            `billboards`,
            `videography`,
            `studio`,
            `commercials`,
            `professional`,
            `rocks`,
            `advertisement`,
            `documentary`,
          ]}
        />
        <SectionsGrid>
          <div className="video-background">
            <div className="video-foreground">
              <ReactPlayer
                pip={false}
                controls={false}
                className="video-iframe"
                url="https://www.youtube.com/watch?v=KY66AraA4HI"
                playing
                volume={100}
                muted={this.state.muted}
                width="100%"
                height="100%"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  objectFit: "cover",
                }}
              />
            </div>
          </div>
          {sectionsData.map((section, index) => (
            <HomeSectionTemplate
              key={index}
              background={section.node.acf.cover.source_url}
              header={section.node.acf.title}
              description={section.node.acf.description}
              buttonText={"View all " + section.node.acf.title}
              link={section.node.slug}
            />
          ))}
        </SectionsGrid>
      </Layout>
    )
  }
}

export default IndexPage

export const sectionsQuery = graphql`
  query GetHomeSections($category: String = "Home Section") {
    allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $category } } } }
    ) {
      edges {
        node {
          title
          slug
          acf {
            title
            description
            cover {
              source_url
            }
          }
        }
      }
    }
  }
`

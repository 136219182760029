import React from "react"
import styled from "styled-components"
import MainButton from "./mainButton"
import { Link } from "gatsby"
import PageHeader from "./pageHeader"

const Container = styled.div`
  z-index: 0;
  height: 100vh;
  overflow: hidden;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 5%;
  scroll-snap-align: start;
`
const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
  width: 100%;
  object-fit: cover;
  filter: brightness(0.7);
`
const Header = styled.h1`
  font-family: "alternategothic2_btregular";
  color: white;
  text-transform: uppercase;
  font-size: 24px;
  position: absolute;
  top: 19%;
  left: 5%;
`
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 750px;
  align-items: center;
  margin: 0 auto;
`
const Description = styled.h1`
  font-family: "alternategothic2_btregular";
  color: white;
  font-size: 24px;
  font-weight: normal;
  font-weight: 400;
  line-height: 35px;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  margin-bottom: 50px;

  @media (min-width: 600px) {
    font-size: 32px;
    line-height: 45px;
  }

  @media (min-width: 700px) {
    font-size: 35px;
    line-height: 50px;
  }
`

class HomeSectionTemplate extends React.PureComponent {
  render() {
    //Check to see if its the leading video for the homepage
    if (this.props.isVideo) {
      return <Container></Container>
    } else {
      return (
        <Container>
          <BackgroundImage src={this.props.background} />
          <PageHeader>{this.props.header}</PageHeader>
          <Content>
            <Description>{this.props.description}</Description>

            <Link
              style={{
                color: "inherit",
                textDecoration: "inherit",
                alignSelf: "flex-start",
              }}
              to={`/${this.props.link}`}
            >
              <MainButton>{this.props.buttonText}</MainButton>
            </Link>
          </Content>
        </Container>
      )
    }
  }
}

export default HomeSectionTemplate

import styled from "styled-components"

const MainButton = styled.button`
  color: black;
  cursor: pointer;
  background: white;
  font-family: "alternategothic2_btregular";
  text-transform: uppercase;
  border: none;
  padding: 10px 15px;

  :focus {
    outline: none;
  }
`

export default MainButton

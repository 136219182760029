import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import SEO from "./seo"

const Container = styled(motion.div)`
  height: 100vh;
  /* max-height: 100vh; */
  width: 100vw;
  /* max-width: 100vw; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
  position: relative;
  overflow: hidden;
`
const Cover = styled.img`
  position: absolute;
  width: 100vw;
  height: 100vh;
  margin: 0;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 0;
`
const EnterButton = styled(motion.button)`
  font-family: "alternategothic2_btregular";
  color: black;
  background: white;
  padding: 25px 40px;
  font-size: 2.8rem;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  z-index: 10;

  transition: 0.3s ease;

  :hover {
    color: white;
    background: black;
  }

  :focus {
    outline: none;
  }
`
class SiteIntro extends React.Component {
  state = {
    image: "",
  }

  componentDidMount() {
    const randomImage = this.props.images[
      Math.floor(Math.random() * this.props.images.length)
    ]

    this.setState({ image: randomImage })
  }

  render() {
    const { image } = this.state
    return (
      <Container
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
      >
        <SEO
          title="Home"
          keywords={[
            `film`,
            `trinidad`,
            `tobago`,
            `local`,
            `photography`,
            `billboards`,
            `videography`,
            `studio`,
            `commercials`,
            `professional`,
            `rocks`,
            `advertisement`,
            `documentary`,
          ]}
        />

        <Cover src={image} />

        <EnterButton
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          onClick={() => this.props.removeIntro()}
        >
          Enter
        </EnterButton>
      </Container>
    )
  }
}

export default SiteIntro
